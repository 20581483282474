/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Gender } from '@global/utils/domain/entities';
import { getDownloadAppUrl, GetDownloadAppUrlInput, GetDownloadAppUrlInputKind } from '@global/utils/native/get-download-app-url';
import UtmDataSource from '@lp-root/src/data/utm.datasource';

export interface LandingPageGetDownloadAppUrlInput {
  // dynamic link id : this is actually the HCP id
  kind?: GetDownloadAppUrlInputKind;
  dl?: string;
  gender?: Gender;
  name?: string;

  // user info
  user_name?: string;
  user_gender?: string;
  user_email?: string;
  user_coupon?: string;

  utm_name?: string;
  utm_medium?: string;
  utm_source?: string;
}

export function landingPageGetDownloadAppUrl(input: LandingPageGetDownloadAppUrlInput, ofl?: string): string {
  const urlInput: GetDownloadAppUrlInput = {
    ...input,
    landingPageUrl: process.env.GATSBY_LANDING_PAGE_URL,
    domain: process.env.GATSBY_DEEP_LINK_DOMAIN,
    apn: process.env.GATSBY_DEEP_LINK_APN,
    isi: process.env.GATSBY_DEEP_LINK_ISI,
    ibi: process.env.GATSBY_DEEP_LINK_IBI,
    utm_medium: UtmDataSource.medium || input.utm_medium,
    utm_source: UtmDataSource.source || input.utm_source,
  };
  return getDownloadAppUrl(urlInput, ofl);
}
