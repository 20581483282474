import { GetCompanyCouponUrl, GetCompanyCouponUrlRequest, GetCompanyCouponUrlResponse } from '@global/utils/api/coupon.controller.model';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import QuizTypeformDataSource from '@lp-root/src/data/quiz-typeform.datasource';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, Col, Grid, H1, H3, Row, Separator } from '@web/atomic';
import { CardWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { useGetUsingNoAuth } from '@web/data/use-get-with-no-auth';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';
import CleanLayout from '../components/org.layout/clean-layout.component';
import CouponCode from '../modules/order-success/coupon-code.component';
import { landingPageGetDownloadAppUrl } from '../modules/order-success/get-download-app-url';
import { QuizB2bResultsRow } from '../modules/quiz-b2b/quiz-b2b-results-row.component';
import { mapBrazilianGender } from '../modules/quiz-b2b/quiz-typeform.container';

const ResultsQuizzPVPage: React.FunctionComponent<PageProps> = (props) => {
  const params: GetCompanyCouponUrlRequest = {
    name: QuizTypeformDataSource.quizTypeform.company,
  };
  const { data, loading, error, refetch } = useGetUsingNoAuth<GetCompanyCouponUrlRequest, GetCompanyCouponUrlResponse>({
    url: GetCompanyCouponUrl,
    params,
  });

  const code = data?.coupon;

  return (
    <CleanLayout>
      <SEO
        robots={'noindex'}
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Resultados do questionário de insônia',
          description: 'Entenda sua insônia e confira uma dica personalizada que preparamos pra você',
        }}
      />
      <Grid>
        <Separator />
        {code && (
          <CardWrapper>
            <H3>Aproveite este benefício!</H3>
            <Body>
              Comece o programa da Vigilantes do Sono usando este código dentro do app para receber os benefícios da nossa parceria. Seu
              código é:
            </Body>
            <CouponCode code={code} loading={loading} />
          </CardWrapper>
        )}
        <Separator />
        <Separator />

        <Row center={'xs'} mt>
          <Col xs={12} sm={12} md={9} lg={8}>
            <H1 center>Resultados do Questionário </H1>
          </Col>
        </Row>

        <QuizB2bResultsRow
          cta={{
            onReloadClick: refetch,
            error: !!error,
            loading: loading,
            onClick: !code
              ? null
              : (): void => {
                  const data = QuizTypeformDataSource.quizTypeform;
                  const url = landingPageGetDownloadAppUrl({
                    kind: 'onboarding',
                    user_name: data.name,
                    user_gender: mapBrazilianGender(data.gender),
                    user_email: data.email,
                    user_coupon: code,
                    utm_name: 'SuperApp PV',
                    utm_medium: 'Dynamic Link',
                    utm_source: 'Psicologia Viva',
                  });
                  try {
                    console.log('navigate to url', url);
                    window.parent.postMessage({ abrirUrlCompleta: url }, '*');
                  } catch (error) {
                    console.error('ERROR: resultado-quizz-pv-657098d6519bbb41b1d8415d949385693f63458e.tsx ~ line 57 ~ error', error);
                    window.open(url, '_blank');
                  }
                },
            code,
          }}
        />
      </Grid>
    </CleanLayout>
  );
};

export default ResultsQuizzPVPage;

export const query = graphql`
  query ResultsQuizzPVQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
