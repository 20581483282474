import { JsonObject } from 'type-fest';
import { useGet, UseGetInput, UseGetResponse, UseGetState } from './use-get.hook';
import { VigilantesDataSource } from './vigilantes.datasource';

export function useGetUsingNoAuth<Request = JsonObject, Response = JsonObject>(
  input: UseGetInput<Request, Response>
): UseGetResponse<Response> {
  const datasource = new VigilantesDataSource({ getAuthHeader: async () => ({}) });
  return useGet<Request, Response>(input, datasource);
}
